import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTax = props => {
  const { page, transaction, currency, intl, tax } = props;

  const displayedPercentage = tax?.percentage ? `${tax?.percentage}%` : '0%';

  return (
    <>
      {page === 'CheckoutPage' ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.tax" values={{percentage: displayedPercentage}}/>
          </span>
          <span className={css.itemValue}>{tax ? `$${tax?.total}` : '---'}</span>
        </div>
      ) : <></>}
    </>
  );
};

LineItemTax.propTypes = {
  intl: intlShape.isRequired,
  page: string,
  transaction: propTypes.transaction.isRequired,
};

export default LineItemTax;
