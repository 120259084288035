import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_RELATED_PRODUCTS_REQUEST = 'app/RelatedProducts/FETCH_RELATED_PRODUCTS_REQUEST';
export const FETCH_RELATED_PRODUCTS_SUCCESS = 'app/RelatedProducts/FETCH_RELATED_PRODUCTS_SUCCESS';
export const FETCH_RELATED_PRODUCTS_ERROR = 'app/RelatedProducts/FETCH_RELATED_PRODUCTS_ERROR';

export const FETCH_FAVORITE_COUNT_REQUEST = 'app/ListingPage/FETCH_FAVORITE_COUNT_REQUEST';
export const FETCH_FAVORITE_COUNT_SUCCESS = 'app/ListingPage/FETCH_FAVORITE_COUNT_SUCCESS';
export const FETCH_FAVORITE_COUNT_ERROR = 'app/ListingPage/FETCH_FAVORITE_COUNT_ERROR';

export const UPDATE_FAVORITE_COUNT_REQUEST = 'app/ListingPage/UPDATE_FAVORITE_COUNT_REQUEST';
export const UPDATE_FAVORITE_COUNT_SUCCESS = 'app/ListingPage/UPDATE_FAVORITE_COUNT_SUCCESS';
export const UPDATE_FAVORITE_COUNT_ERROR = 'app/ListingPage/UPDATE_FAVORITE_COUNT_ERROR';

export const FETCH_SOLD_AMOUNT_REQUEST = 'app/ListingPage/FETCH_SOLD_AMOUNT_REQUEST';
export const FETCH_SOLD_AMOUNT_SUCCESS = 'app/ListingPage/FETCH_SOLD_AMOUNT_SUCCESS';
export const FETCH_SOLD_AMOUNT_ERROR = 'app/ListingPage/FETCH_SOLD_AMOUNT_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  relatedProducts: {},
  fetchRelatedProductsInProgress: false,
  fetchRelatedProductsError: null,
  soldAmount: 0,
  fetchSoldAmountInProgress: false,
  fetchSoldAmountError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_SOLD_AMOUNT_REQUEST:
      return { ...state, fetchSoldAmountInProgress: true, fetchSoldAmountError: null };
    case FETCH_SOLD_AMOUNT_SUCCESS:
      return { ...state, fetchSoldAmountInProgress: false, soldAmount: action.payload };
    case FETCH_SOLD_AMOUNT_ERROR:
      return { ...state, fetchSoldAmountInProgress: false, fetchSoldAmountError: action.payload };

    case FETCH_FAVORITE_COUNT_REQUEST:
      return { ...state, fetchFavoriteCountInProgress: true };
    case FETCH_FAVORITE_COUNT_SUCCESS:
      return { ...state, fetchFavoriteCountInProgress: false, favoriteCount: payload };
    case FETCH_FAVORITE_COUNT_ERROR:
      return { ...state, fetchFavoriteCountInProgress: false, fetchFavoriteCountError: payload };

    case UPDATE_FAVORITE_COUNT_REQUEST:
      return { ...state, updateFavoriteCountInProgress: true };
    case UPDATE_FAVORITE_COUNT_SUCCESS:
      return { ...state, updateFavoriteCountInProgress: false };
    case UPDATE_FAVORITE_COUNT_ERROR:
      return {
        ...state,
        updateFavoriteCountInProgress: false,
        updateFavoriteCountError: action.payload,
      };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    case FETCH_RELATED_PRODUCTS_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchError: null,
      };
    case FETCH_RELATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        relatedProducts: payload.data,
        fetchInProgress: false,
      };
    case FETCH_RELATED_PRODUCTS_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchError: payload,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

const fetchFavoriteCountRequest = () => ({ type: FETCH_FAVORITE_COUNT_REQUEST });
const fetchFavoriteCountSuccess = count => ({ type: FETCH_FAVORITE_COUNT_SUCCESS, payload: count });
const fetchFavoriteCountError = error => ({ type: FETCH_FAVORITE_COUNT_ERROR, payload: error });

const updateFavoriteCountRequest = () => ({ type: UPDATE_FAVORITE_COUNT_REQUEST });
const updateFavoriteCountSuccess = () => ({ type: UPDATE_FAVORITE_COUNT_SUCCESS });
const updateFavoriteCountError = error => ({ type: UPDATE_FAVORITE_COUNT_ERROR, payload: error });

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const fetchRelatedProductsRequest = listingId => ({
  type: FETCH_RELATED_PRODUCTS_REQUEST,
  payload: { listingId },
});

export const fetchRelatedProductsSuccess = data => ({
  type: FETCH_RELATED_PRODUCTS_SUCCESS,
  payload: { data },
});

export const fetchRelatedProductsError = error => ({
  type: FETCH_RELATED_PRODUCTS_ERROR,
  error: true,
  payload: error,
});

const fetchProductsRequest = () => ({
  type: 'FETCH_PRODUCTS_REQUEST',
});

const fetchProductsSuccess = products => ({
  type: 'FETCH_PRODUCTS_SUCCESS',
  payload: products,
});

const fetchProductsError = error => ({
  type: 'FETCH_PRODUCTS_ERROR',
  error: true,
  payload: error,
});

const fetchSoldAmountRequest = () => ({ type: FETCH_SOLD_AMOUNT_REQUEST });
const fetchSoldAmountSuccess = soldAmount => ({
  type: FETCH_SOLD_AMOUNT_SUCCESS,
  payload: soldAmount,
});
const fetchSoldAmountError = error => ({ type: FETCH_SOLD_AMOUNT_ERROR, payload: error });

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const fetchFavoriteCount = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchFavoriteCountRequest());

  return sdk.listings
    .show({
      id: listingId,
      'fields.listing': ['publicData'],
      // include any other necessary fields
    })
    .then(response => {
      // Assuming the favorite count is stored in listing's publicData
      const favoriteCount = response.data.data.attributes.publicData.favoriteCount || 0;
      dispatch(fetchFavoriteCountSuccess(favoriteCount));
      return favoriteCount;
    })
    .catch(e => {
      dispatch(fetchFavoriteCountError(storableError(e)));
      throw e;
    });
};

export const updateListingFavoriteCount = (listingId, favoriteCount) => dispatch => {
  dispatch(updateFavoriteCountRequest());

  return fetch('http://localhost:3500/api/updateItemFavoriteCount', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ listingId, favoriteCount }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        dispatch(updateFavoriteCountSuccess());
      } else {
        throw new Error('Failed to update favorite count');
      }
    })
    .catch(error => {
      dispatch(updateFavoriteCountError(storableError(error)));
    });
};

export const loadData = (params, search, config) => dispatch => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
  ]).then(response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }
    return response;
  });
};

const params = {
  minStock: 1,
  page: 1,
  include: ['author', 'images'],
  'fields.listing': [
    'title',
    'geolocation',
    'price',
    'publicData.public_tag',
    'publicData.listingType',
    'publicData.transactionProcessAlias',
    'publicData.unitType',
    'publicData.pickupEnabled',
    'publicData.shippingEnabled',
    'publicData.series_title',
    'publicData.sold'
  ],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
  'fields.image': [
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.listing-card',
    'variants.listing-card-2x',
  ],
  'imageVariant.listing-card': 'w:400;h:533;fit:crop',
  'imageVariant.listing-card-2x': 'w:800;h:1067;fit:crop',
  'limit.images': 1,
  perPage: 24,
};

export const combineImageAndProduct = (imagesAndAuthors, productListingInfo) => {
  const imageMap = {};
  const authorMap = {};

  imagesAndAuthors.forEach(item => {
    if (item.type === 'image') {
      imageMap[item.id.uuid] = item;
    } else if (item.type === 'user') {
      authorMap[item.id.uuid] = item;
    }
  });

  const productListingWithImagesAndAuthors = productListingInfo.map(listing => {
    const imageIds = listing.relationships?.images?.data?.map(imageData => imageData.id.uuid) || [];
    const images = imageIds
      .map(imageId => {
        return imageMap[imageId];
      })
      .filter(image => image);

    const authorId = listing.relationships?.author?.data?.id?.uuid;
    const author = authorMap[authorId] || null;

    return {
      ...listing,
      images,
      author,
    };
  });

  return productListingWithImagesAndAuthors;
};

export const fetchRelatedProducts = (listingId, authorId) => (dispatch, getState, sdk) => {
  const relatedItems = sdk.listings.show({ id: listingId }).then(response => {
    let keyWord;
    if (response.data.data.attributes.publicData.series_title) {
      keyWord = response.data.data.attributes.publicData.series_title
    }

    const relatedItemsParams = {
      ...params,
      keywords: keyWord,
    };
    return sdk.listings.query(relatedItemsParams).then(response => {
      return response.data;
    });
  });

  const itemsFromSellerParams = {
    ...params,
    authorId: authorId,
  };
  const itemsFromSeller = sdk.listings.query(itemsFromSellerParams).then(response => {
    return response.data;
  });

  return Promise.all([relatedItems, itemsFromSeller])
    .then(([relatedItemsResponse, itemsFromSellerResponse]) => {
      const relatedItemsPayload = combineImageAndProduct(
        relatedItemsResponse?.included || [],
        relatedItemsResponse?.data || []
      );
      const itemsFromSellerPayload = combineImageAndProduct(
        itemsFromSellerResponse?.included || [],
        itemsFromSellerResponse?.data || []
      );

      const mergedResponse = {
        relatedItems: relatedItemsPayload?.filter(item => item.id.uuid !== listingId) || [],
        itemsFromSeller: itemsFromSellerPayload?.filter(item => item.id.uuid !== listingId) || [],
      };

      dispatch(fetchRelatedProductsSuccess(mergedResponse));
      return mergedResponse;
    })
    .catch(error => {
      console.error('Error fetching related items:', error);
      throw error;
    });
};

export const combineImageWithProduct2 = (included, productListingInfo) => {
  const imageMap = {};
  const authorMap = {};

  // Populate the imageMap and authorMap with the corresponding data
  included.forEach(item => {
    if (item.type === 'image') {
      imageMap[item.id.uuid] = item;
    } else if (item.type === 'user') {
      authorMap[item.id.uuid] = item;
    }
  });

  // Combine each product listing with its corresponding images and author
  const productListingWithImagesAndAuthors = productListingInfo.map(listing => {
    const imageIds = listing.relationships.images.data.map(imageData => imageData.id.uuid);

    // Fetch the images from the imageMap using the IDs
    const images = imageIds.map(imageId => imageMap[imageId]);

    // Fetch the author from the authorMap using the ID
    const authorId = listing.relationships?.author?.data?.id?.uuid;
    const author = authorMap[authorId] || null;

    return {
      ...listing,
      images, // Add the images directly to the listing
      author, // Add the author directly to the listing
    };
  });

  return productListingWithImagesAndAuthors[0];
};

export const fetchProductsByIds = productIds => (dispatch, getState, sdk) => {
  // Dispatch an action to indicate the start of fetching (optional)
  dispatch(fetchProductsRequest());

  // Use Promise.all to wait for all listings to be fetched
  return Promise.all(
    productIds.map(productId =>
      sdk.listings
        .show({ ...params, id: productId.id.uuid })
        .then(response => {
          const data = combineImageWithProduct2(
            response.data?.included || [],
            [response.data?.data] || []
          );
          return data;
        })
        .catch(error => {
          console.error(`Error fetching product with ID ${productId}:`, error);
          // Handle the error appropriately (e.g., return null or a specific error object)
          return null;
        })
    )
  )
    .then(products => {
      // Dispatch an action with the fetched products data
      dispatch(fetchProductsSuccess(products));
      return products;
    })
    .catch(error => {
      // Dispatch an action in case of an error during the fetching process
      dispatch(fetchProductsError(error));
      throw error;
    });
};

export const fetchSoldAmount = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchSoldAmountRequest());

  return sdk.listings
    .show({ id: listingId, 'fields.listing': ['publicData'] })
    .then(response => {
      const soldAmount = response?.data?.data?.attributes?.publicData?.soldAmount || 0;
      dispatch(fetchSoldAmountSuccess(soldAmount));
      return soldAmount;
    })
    .catch(e => {
      dispatch(fetchSoldAmountError(storableError(e)));
      throw e;
    });
};

export const fetchFeaturedItems = () => (dispatch, getState, sdk) => {
  const featuredItemsParam = {
    ...params,
    pub_favoriteCount: '1,',
    perPage: 10,
    sort: 'pub_favoriteCount',
  };

  return sdk.listings
    .query(featuredItemsParam)
    .then(response => {
      const transformedData = transformData(response.data);
      return transformedData;
    })
    .catch(error => {
      console.error('Error fetching featured items:', error);
    });
};

function transformData(responseData) {
  // Map through each listing in the data array
  return responseData.data.map(listing => {
    // Extract images data from included section based on relationship
    const images = listing.relationships.images.data.map(imgRel => {
      const image = responseData.included.find(include => include.id.uuid === imgRel.id.uuid);
      return {
        id: imgRel.id.uuid,
        type: image.type,
        attributes: image.attributes,
      };
    });

    // Extract author data from included section based on relationship
    const authorRel = listing.relationships.author.data;
    const author = responseData.included.find(include => include.id.uuid === authorRel.id.uuid);

    // Return the new structure as an object, not wrapped in an array
    return {
      id: listing.id,
      type: listing.type,
      attributes: listing.attributes,
      relationships: listing.relationships,
      images: images,
      author: {
        id: author.id.uuid,
        type: author.type,
        attributes: author.attributes,
      },
    };
  });
}
