// Import statements remain the same
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldDropdown.module.css';

const FieldDropdownComponent = props => {
  const { rootClassName, className, id, label, input, meta, options, isMulti, ...rest } = props;

  const onChange = option => {
    if (isMulti) {
      input.onChange(option.map(o => o.value));
    } else {
      // Log the action to see if clearing is being triggered
      if (option === null) {
      }
      input.onChange(option ? option.value : null);
    }
  };

  const valueProp = isMulti
    ? options.filter(option => input.value.includes(option.value))
    : options.find(option => option.value === input.value);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      margin: 0,
      padding: '6px 12px 4px 12px',
      backgroundColor: '#fff',
      borderRadius: '6px',
      border: '1px solid #d8dce6',
      boxShadow: state.isFocused ? 'none' : '0 2px 3px 0 rgba(216, 220, 230, 0.7)',
      outline: 'none',
      transition: 'all ease-in-out 100ms',
      color: '#333',
      '&:hover': {
        borderColor: '#b3b6bc',
      },
      ...(state.isFocused && {
        borderColor: '#2684FF',
        boxShadow: '0 0 0 3px rgba(38, 132, 255, 0.5)',
      }),
    }),
  };

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const callback = args[args.length - 1]; // Assume the last argument is the callback
      const context = this;

      const later = () => {
        timeout = null;
        func.apply(context, args.slice(0, -1)).then(result => callback(result));
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const loadAnimeOptions = async inputValue => {
    if (!inputValue) {
      return Promise.resolve([]); // Return a promise that resolves to an empty array
    }

    try {
      const response = await fetch(`https://api.jikan.moe/v4/anime?q=${inputValue}&limit=16`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      return data.data.map(anime => ({
        label: anime.title,
        value: anime.title,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      return Promise.resolve([]); // Return a promise that resolves to an empty array in case of error
    }
  };

  const debouncedLoadAnimeOptions = debounce(loadAnimeOptions, 300);

  return (
    <div className={rootClassName || css.root}>
      {label && <label htmlFor={id}>{label} (Optional)</label>}
      <AsyncSelect
        cacheOptions
        defaultOptions
        isClearable={true}
        id={id}
        className={className}
        styles={customStyles}
        value={valueProp} // The value prop is managed by the final-form Field state
        onChange={onChange} // Call final-form's input.onChange when the value changes
        isMulti={isMulti}
        loadOptions={(inputValue, callback) => debouncedLoadAnimeOptions(inputValue, callback)}
        {...rest}
      />
      {meta.touched && meta.error && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

// Define default props if necessary
FieldDropdownComponent.defaultProps = {
  isMulti: false,
};

// Define propTypes for type checking
FieldDropdownComponent.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMulti: PropTypes.bool,
};

// The main component wrapper using react-final-form's Field
const FieldDropdown = props => {
  return <Field component={FieldDropdownComponent} {...props} />;
};

// Export the renamed component
export default FieldDropdown;
