import { updatedEntities, denormalisedEntities } from '../util/data';

// ================ Action types ================ //

export const ADD_MARKETPLACE_ENTITIES = 'app/marketplaceData/ADD_MARKETPLACE_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  entities: {},
};

const merge = (state, payload) => {
  const { sdkResponse, sanitizeConfig } = payload;
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse, sanitizeConfig),
  };
};

export default function marketplaceDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MARKETPLACE_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
  const { entities } = state.marketplaceData;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

/**
 * Get the denormalised entities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to entities that
 * we want to query from the data. Currently we expect that all the
 * entities have the same type.
 *
 * @return {Array<Object>} denormalised entities
 */
export const getMarketplaceEntities = (state, entityRefs) => {
  const { entities } = state.marketplaceData;
  const throwIfNotFound = false;
  return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};

// ================ Action creators ================ //

export const addMarketplaceEntities = (sdkResponse, sanitizeConfig) => ({
  type: ADD_MARKETPLACE_ENTITIES,
  payload: { sdkResponse, sanitizeConfig },
});

export const fetchListingsByIds = async (productIDs, sdk) => {
  if (!sdk) {
    console.error('SDK object is undefined');
    throw new Error('SDK object is undefined');
  }

  // Check if sdk.listings is undefined
  if (!sdk.listings) {
    console.error('SDK object does not have listings property');
    throw new Error('SDK object does not have listings property');
  }

  // Check if sdk.listings.show is a function
  if (typeof sdk.listings.show !== 'function') {
    console.error('listings.show is not a function on the SDK object');
    throw new Error('listings.show is not a function on the SDK object');
  }

  try {
    const listingsPromises = productIDs.map(id => {
      return sdk.listings
        .show({ id: id })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error(`Error occurred when fetching data for product ID ${id}:`, error);
          throw error;
        });
    });
    const listings = await Promise.all(listingsPromises);
    return listings;
  } catch (error) {
    console.error('An error occurred in fetchListingsByIds:', error);
    throw error;
  }
};
